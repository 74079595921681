import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';

import FormInput from '../UI/FormInput';

import { setPin } from '../../services/user';

import { ActionCreator } from '../../store/actions/user';

import { indicators, controls } from '../../utils/constants';

import styles from './UserPinSetForm.module.scss';

const UserPinSetForm = ({ setStep }) => {
  const [field, setField] = useState('code');

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      code: '',
      confirmCode: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    // code: Yup.string()
    //   .length(4, 'Введите корректный код')
    //   .required('Введите код'),
    // confirmCode: Yup.string()
    //   .length(4, 'Введите корректный код')
    //   .required('Введите код'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (values.code === values.confirmCode) {
        dispatch(
          setPin({
            code: values.code,
          }),
        )
          .then(() => {
            Cookies.set('pinStatus', 1);
            setStep(4);
          })
          .catch((err) => {
            console.log(err);
            toast.error('Произошла ошибка при отправке данных');
          });
      } else {
        formik.resetForm();
        setField('code');
        toast.error('PIN-коды не совпадают. Попробуюте еще раз');
      }
    },
  });

  useEffect(() => {
    if (formik.values.code.length === 4) {
      setField('confirmCode');
    }
  }, [formik.values.code]);

  useEffect(() => {
    if (formik.values.confirmCode.length === 4) {
      formik.submitForm();
    }
  }, [formik.values.confirmCode]);

  const getIndicatorClassName = (item) => {
    return formik.values[field].length >= item
      ? `${styles.indicator} ${styles['indicator--active']}`
      : styles.indicator;
  };

  const onSymbolButtonClick = (evt) => {
    formik.setFieldValue(
      [field],
      `${formik.values[field]}${evt.target.textContent}`,
    );
  };

  const onDeleteButtonClick = () => {
    formik.setFieldValue([field], `${formik.values[field].slice(0, -1)}`);
  };

  const onExitButtonClick = () => {
    Cookies.remove('accessToken');
    Cookies.remove('sessionId');
    Cookies.remove('pinStatus');
    dispatch(ActionCreator.resetStore());
    setStep(1);
  };

  const getControl = (item) => {
    switch (item.type) {
      case 'text':
        return <button className={styles['button-text']}>{item.value}</button>;

      case 'image':
        return (
          <button
            className={styles['button-text']}
            onClick={onDeleteButtonClick}
          >
            <img src={item.value} alt="delete button" />
          </button>
        );

      default:
        return (
          <button
            className={styles['button-symbol']}
            onClick={onSymbolButtonClick}
          >
            {item.value}
          </button>
        );
    }
  };

  return (
    <>
      <p className={styles.header}>Установка PIN-кода</p>
      <div className={styles.top}>
        <p className={styles.title}>
          {field === 'code'
            ? 'Введите код доступа'
            : 'Введите код доступа повторно'}
        </p>
        <div className={styles['indicators-wrapper']}>
          {indicators.map((item) => (
            <div className={getIndicatorClassName(item)} key={item}></div>
          ))}
        </div>
      </div>
      <div className={styles.bottom}>
        {controls.map((item) => (
          <div className={styles['button-wrapper']} key={item.value}>
            {getControl(item)}
          </div>
        ))}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <FormInput
          formik={formik}
          name="code"
          type="hidden"
          fieldType="masked-input"
          mask="9999"
          maskChar="X"
        />
        <FormInput
          formik={formik}
          name="confirmCode"
          type="hidden"
          fieldType="masked-input"
          mask="9999"
          maskChar="X"
        />
      </form>
      <button className={styles['button-exit']} onClick={onExitButtonClick}>
        Выйти
      </button>
    </>
  );
};

export default UserPinSetForm;
