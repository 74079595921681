import React from 'react';

import { transactionTypes } from '../../../utils/constants';

import styles from './TransactionMainInfo.module.scss';

const TransactionMainInfo = ({
  transactionInfo,
  result,
  rate,
}) => {

  return (
    <div className={styles.mainInfo}>
      <div className={styles.image}>
        <img src={transactionInfo.image} alt={'transaction icon'} />
        <div className={styles.success}></div>
      </div>
      <div className={styles.titleContain}>
        {transactionInfo.type === transactionTypes.CONVERSION && (
          <>
            <div className={styles[transactionInfo.selector]}>
              +{result.toAmount} {result.toCurrency.toUpperCase()}
            </div>
            <div className={styles.secondTitle}>
              -{result.fromAmount} {result.fromCurrency.toUpperCase()}
            </div>
          </>
        )}
        {transactionInfo.type === transactionTypes.SEND && (
          <>
            <div className={styles[transactionInfo.selector]}>
              -{result.amount} {result.currency.toUpperCase()}
            </div>
            <div className={styles.secondTitle}>
              {result.account}
            </div>
          </>
        )}
        {transactionInfo.type === transactionTypes.BUY && (
          <>
            <div className={styles[transactionInfo.selector]}>
              +{result.amount} {result.currency}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionMainInfo;
