import { combineReducers } from 'redux';
import conversion from './_conversion';
import history from './_history';
import user from './_user';
import wallets from './_wallets';

const reducers = {
  conversion,
  history,
  user,
  wallets,
};

export default combineReducers(reducers);
