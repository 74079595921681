export const ActionType = {
  SET_CURRENCIES: 'SET_CURRENCIES',
};

export const ActionCreator = {
  setCurrencies: (data) => ({
    type: ActionType.SET_CURRENCIES,
    payload: data,
  }),
};
