import React, { useEffect } from "react";
import { useSwiper, useSwiperSlide } from "swiper/react";

import { swiperLogos } from "../../utils/constants";

import styles from "./BalanceCard.module.scss";

const BalanceCard = (props) => {
  const { card, onChangeActiveCard } = props;
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();

  const cardStyle =
    swiperSlide.isActive === true
      ? `${styles.container} ${styles.contActive}`
      : styles.container;

  const onClickCard = (e, swiperSlide) => {
    e.preventDefault();

    swiperSlide.isNext && swiper.slideNext();
    swiperSlide.isPrev && swiper.slidePrev();
  };

  useEffect(() => {
    if (swiperSlide.isActive === true) {
      onChangeActiveCard(card);
    }
  }, [swiperSlide.isActive]);

  return (
    <button className={cardStyle} onClick={(e) => onClickCard(e, swiperSlide)}>
      <div className={styles.info}>
        <div className={styles.text}>Баланс {card.name}</div>
        <div className={styles.value}>{card.balance}</div>
      </div>
      <img src={swiperLogos[card.type]} className={styles.ImgCurrency} alt="currency-type" />
    </button>
  );
};

export default BalanceCard;
