import { ActionType } from '../actions/history';

const initialState = {
  history: [],
  currenciesList: [],
};

const setHistory = (state, data) => ({
  ...state,
  history: data,
});

const setCurrenciesList = (state, data) => ({
  ...state,
  currenciesList: data,
});

const history = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_HISTORY:
      return setHistory(state, action.payload);

    case ActionType.SET_CURRENCIES_LIST:
      return setCurrenciesList(state, action.payload);

    default:
      return state;
  }
};

export default history;
