import { useState } from 'react';

import UserDocumentForm from '../UserDocumentForm';
import UserPassportForm from '../UserPassportForm';

import styles from './UserData.module.scss';

const UserData = ({ setStep }) => {
  const [type, setType] = useState('individual');

  const onTypeChange = (evt) => {
    setType(evt.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectors}>
        <div className={styles.selector}>
          <input type="radio" id="userType1" name="userType" defaultChecked value="individual" onChange={onTypeChange} />
          <label htmlFor="userType1">Физ. лицо</label>
        </div>
        <div className={styles.selector}>
          <input type="radio" id="userType2" name="userType" value="legal" onChange={onTypeChange} />
          <label htmlFor="userType2">Юр. лицо</label>
        </div>
      </div>
      {type === 'individual' && <UserPassportForm setStep={setStep} />}
      {type === 'legal' && <UserDocumentForm setStep={setStep} />}
    </div>
  );
};

export default UserData;
