import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserBlock from '../../components/UserBlock';
import MainButtons from '../../components/MainButtons';
// import HistoryFilters from '../../components/HistoryFilters';
import HistoryList from '../../components/HistoryList';

import { initialParams } from '../../utils/constants';

import styles from './MainPage.module.scss';

const MainPage = () => {
  const [activeCard, setActiveCard] = useState(null);
  const [params, setParams] = useState({ ...initialParams });

  const userInfo = useSelector((state) => state.user.userInfo);

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.status !== 2) {
      navigate('/');
    }
  }, [userInfo]);

  return (
    <>
      <UserBlock setActiveCard={setActiveCard} />
      <MainButtons activeCard={activeCard} setParams={setParams} />
      <div className={styles.historyTitle}>История</div>
      {/* <HistoryFilters params={params} setParams={setParams} /> */}
      <HistoryList params={params} setParams={setParams}/>
    </>
  );
};

export default MainPage;
