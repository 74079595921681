import { ActionType } from '../actions/wallets';

const initialState = {
  wallets: [],
};

const setWallets = (state, data) => ({
  ...state,
  wallets: data,
});

const wallets = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_WALLETS:
      return setWallets(state, action.payload);

    default:
      return state;
  }
};

export default wallets;