import humps from 'humps';

import { ActionCreator } from '../store/actions/wallets';

const getWallets = () => (dispatch, _getState, api) => api
  .get('/wallets')
  .then(({ data }) => {
    dispatch(ActionCreator.setWallets(humps.camelizeKeys(data)));
  });

const getWalletPayoutStatus = (id) => (dispatch, _getState, api) => api.get(`/wallets/payout/${id}`);

const createWalletPayout = (currency, params) => (dispatch, _getState, api) => api.post(`/wallets/payout/${currency}`, {}, { params: humps.decamelizeKeys(params) });

export { getWallets, getWalletPayoutStatus, createWalletPayout };
