export const ActionType = {
  SET_WALLETS: 'SET_WALLETS',
};

export const ActionCreator = {
  setWallets: (data) => ({
    type: ActionType.SET_WALLETS,
    payload: data,
  }),
};
