import humps from 'humps';

import { ActionCreator } from '../store/actions/conversion';

const getCurrencies = (params) => (dispatch, _getState, api) => api
  .get('/conversion/currencies', { params: humps.decamelizeKeys(params) })
  .then(({ data }) => {
    dispatch(ActionCreator.setCurrencies(humps.camelizeKeys(data)));
  });

const getConversionRate = (params) => (dispatch, _getState, api) => api.get('/conversion/rate', { params: humps.decamelizeKeys(params) });

const getConversionStatus = (id) => (dispatch, _getState, api) => api.get(`/conversion/${id}`);

const createConversion = (params) => (dispatch, _getState, api) => api
  .post('/conversion/', {}, { params: humps.decamelizeKeys(params) })
  .then(({ data }) => {
    return humps.camelizeKeys(data);
  });

export { getCurrencies, getConversionRate, getConversionStatus, createConversion };
