import React from "react";
import styles from "./HistoryHeadNav.module.scss";
import NavBackIcon from "../../../assets/images/navBackIcon.png";
import { useNavigate } from "react-router-dom";

const HistoryHeadNav = ({ title }) => {
  const navigate = useNavigate();

  const onClickNavBack = () => {
    navigate(-1);
  };

  return (
    <header className={styles.headNav}>
      <div className={styles.title}>{title}</div>
      <button onClick={onClickNavBack} className={styles.navBack}>
        <img src={NavBackIcon} alt={"navBackIcon"}/>
      </button>
    </header>
  );
};

export default HistoryHeadNav;
