import axios from 'axios';
import Cookies from 'js-cookie';

import { URL_BASE, REQUEST_TIMEOUT } from '../utils/constants';
import { toast } from 'react-hot-toast';

const api = axios.create({
  baseURL: URL_BASE,
  timeout: REQUEST_TIMEOUT,
  // withCredentials: true,
});

api.interceptors.request.use((config) => {
  const pathsWithoutAuth = ['/user/login'];
  const pathsWithSessionId = ['/user/code', '/user/pin', '/user/exit'];

  if (
    !pathsWithoutAuth.includes(config.url) &&
    !pathsWithSessionId.includes(config.url)
  ) {
    config.headers.Authorization = `Bearer ${Cookies.get('accessToken')}`;
  } else if (pathsWithSessionId.includes(config.url)) {
    config.headers.Authorization = `Bearer ${Cookies.get('sessionId')}`;
  }

  return config;
});

api.interceptors.response.use(
  (config) => config,
  (error) => {
    // if (error.response.data.detail) {
    //   toast.error(error.response.data.detail);
    // }
    throw error.response;
  },
);

export default api;
