import { ActionType } from '../actions/user';

const initialState = {
  userInfo: {},
};

const setUserInfo = (state, data) => ({
  ...state,
  userInfo: data,
});

const user = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_USER_INFO:
      return setUserInfo(state, action.payload);

    default:
      return state;
  }
};

export default user;
