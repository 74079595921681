import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

import FormWrapper from '../UI/FormWrapper';
import FormInput from '../UI/FormInput';
import TransactionMainInfo from '../UI/TransactionMainInfo';

import {
  createConversion,
  getConversionRate,
  getCurrencies,
} from '../../services/conversion';
import { getWallets } from '../../services/wallet';

import { initialParams } from '../../utils/constants';

import styles from './FormConversion.module.scss';

const FormConversion = ({
  transactionInfo,
  setTransactionInfo,
  activeCard,
  setParams,
}) => {
  const [result, setResult] = useState(null);
  const [currentRate, setCurrentRate] = useState(null);

  const currencies = useSelector((state) => state.conversion.currencies);

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      from: activeCard.type || '',
      to: '',
      amount: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    from: Yup.string().required('Выберите валюту списания'),
    to: Yup.string().required('Выберите целевую валюту'),
    amount: Yup.string().required('Введите сумму'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      dispatch(createConversion(values))
        .then((data) => {
          setResult(data);
          dispatch(getWallets()).catch(() => {
            toast.error(
              'Не удалось загрузить информацию о кошельках пользователя и их балансах',
            );
          });
          setParams({ ...initialParams });
        })
        .catch((err) => {
          let errorText = '';

          if (err.data.detail) {
            errorText = errorText + err.data.detail;
          }
          if (err.data.non_field_errors) {
            errorText = errorText + err.data.non_field_errors.join('. ');
          }
          
          toast.error(`Произошла ошибка при создании операции. ${errorText} `);
        }),
  });

  useEffect(() => {
    dispatch(getCurrencies({ from: activeCard.type })).catch((err) => {
      toast.error('Не удалось загрузить перечень валют для конвертации');
    });
  }, []);

  useEffect(() => {
    if (currencies.length && currencies[0].garantexId !== formik.values.from) {
      formik.setFieldValue('to', currencies[0].garantexId);
    }
  }, [currencies]);

  useEffect(() => {
    if (formik.values.to) {
      dispatch(
        getConversionRate({ from: formik.values.from, to: formik.values.to }),
      )
        .then(({ data }) => {
          setCurrentRate(data.rate);
        })
        .catch((err) => {
          toast.error('Не удалось загрузить курс для конвертации');
        });
    }
  }, [formik.values.to]);

  const onAmountFieldChange = (value) => {
    formik.setFieldValue('amount', value);
  };

  const onCloseButtonClick = () => setTransactionInfo(null);

  return (
    <>
      {result ? (
        <div className={styles.mainInfoWrapper}>
          <TransactionMainInfo
            transactionInfo={transactionInfo}
            setTransactionInfo={setTransactionInfo}
            result={result}
          />
          <button onClick={onCloseButtonClick} className={styles.button}>
            Закрыть
          </button>
        </div>
      ) : (
        <FormWrapper
          transactionInfo={transactionInfo}
          currency={activeCard.name}
          description="Криптовалюта будет зачислена на ваш кошелек сразу после операции"
          value={formik.values.amount}
          onSubmit={formik.handleSubmit}
        >
          <FormInput
            formik={formik}
            label="Целевая валюта"
            name="to"
            fieldType="select"
            options={currencies.map((item) => ({
              title: item.name,
              value: item.garantexId,
            }))}
          />

          <FormInput
            formik={formik}
            label="Сумма"
            name="amount"
            currency={activeCard.altname}
            onValueChange={onAmountFieldChange}
          />

          <p className={styles.description}>
            1{' '}
            {
              currencies.filter(
                (item) => item.garantexId === formik.values.to,
              )[0]?.altname
            }{' '}
            = {currentRate} {activeCard.altname}
          </p>
        </FormWrapper>
      )}
    </>
  );
};

export default FormConversion;
