import DeleteButton from '../../assets/images/delete.svg';

const indicators = [1, 2, 3, 4];

const controls = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    type: 'text',
    value: 'Забыли код?',
  },
  {
    value: 0,
  },
  {
    type: 'image',
    value: DeleteButton,
  },
];

export { indicators, controls };
