import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';

import UserLoginForm from '../../components/UserLoginForm';
import UserCodeForm from '../../components/UserCodeForm';
import UserPinForm from '../../components/UserPinForm/inden';
import UserData from '../../components/UserData';
import UserAuthStatus from '../../components/UserAuthStatus';
import Loading from '../../components/UI/Loading';

import { getUserInfo } from '../../services/user';

import styles from './Login.module.scss';

import LogoImage from '../../assets/images/logo-MegaCrypto.svg';

const Login = () => {
  const [step, setStep] = useState(1);
  const [isDataRecieved, setIsDataRecieved] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [userPhone, setUserPhone] = useState('');

  const userInfo = useSelector((state) => state.user.userInfo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');

    if (accessToken) {
      setIsDataRecieved(false);
      dispatch(getUserInfo())
        .then(() => setIsDataRecieved(true))
        .catch((err) => {
          if (err.status === 401 || err.status === 403) {
            setStep(3);
          }
          setIsDataRecieved(true);
          // toast.error('Не удалось загрузить информацию о пользователе');
        });
    } else {
      setIsDataRecieved(true);
    }
  }, [step]);

  useEffect(() => {
    setIsComplete(false);
    const pinStatus = Cookies.get('pinStatus');

    if (isDataRecieved) {
      if (pinStatus) {
        switch (userInfo.status) {
          case 1:
            setStep(5);
            break;
          case 2:
            navigate('/main');
            break;
          case 4:
            setStep(5);
            break;

          default:
            break;
        }
      }
      setIsComplete(true);
    }
  }, [isDataRecieved]);

  return (
    <>
      <div className={styles.login__header}>
        <img src={LogoImage} alt="logo" />
      </div>
      <div className={styles.login__wrapper}>
        {isComplete ? (
          <>
            {step === 1 && (
              <UserLoginForm
                setStep={setStep}
                userPhone={userPhone}
                setUserPhone={setUserPhone}
              />
            )}
            {step === 2 && (
              <UserCodeForm userPhone={userPhone} setStep={setStep} />
            )}
            {step === 3 && <UserPinForm setStep={setStep} />}
            {step === 4 && <UserData setStep={setStep} />}
            {step === 5 && (
              <UserAuthStatus userInfo={userInfo} setStep={setStep} />
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default Login;
