import SubmitButton from '../SubmitButton';

import styles from './FormWrapper.module.scss';

const FormWrapper = ({
  children,
  transactionInfo,
  currency,
  description,
  value,
  onSubmit,
}) => {
  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.container}>
        <h2 className={styles.title}>
          {transactionInfo.title} {currency}
        </h2>
        <p className={styles.description}>{description}</p>
        <div className={styles.inputContainer}>{children}</div>
      </div>
      <SubmitButton title={transactionInfo.title.toUpperCase()} value={value} currency={currency} />
    </form>
  );
};

export default FormWrapper;
