import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';

import FormInput from '../UI/FormInput';

import { checkPin } from '../../services/user';

import { ActionCreator } from '../../store/actions/user';

import { indicators, controls } from '../../utils/constants';

import styles from './UserPinCheckForm.module.scss';

const UserPinCheckForm = ({ setStep }) => {
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      code: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    code: Yup.string()
      .length(4, 'Введите корректный код')
      .required('Введите код'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      dispatch(checkPin({
        ...values,
        deviceId: 'sampleText',
      }))
        .then(() => {
          setStep(4);
        })
        .catch(() => {
          toast.error('Произошла ошибка при отправке данных');
        }),
  });

  useEffect(() => {
    console.log(formik.values.code);
    if (formik.values.code.length === 4) {
      formik.submitForm();
    }
  }, [formik.values.code]);

  const getIndicatorClassName = (item) => {
    return formik.values.code.length >= item
      ? `${styles.indicator} ${styles['indicator--active']}`
      : styles.indicator;
  };

  const onSymbolButtonClick = (evt) => {
    formik.setFieldValue('code', `${formik.values.code}${evt.target.textContent}`);
  };

  const onForgotButtonClick = () => {
    Cookies.remove('accessToken');
    Cookies.remove('sessionId');
    Cookies.remove('pinStatus');
    dispatch(ActionCreator.resetStore());
    setStep(1);
  };

  const onDeleteButtonClick = () => {
    formik.setFieldValue('code', `${formik.values.code.slice(0, -1)}`);
  };

  const onExitButtonClick = () => {
    Cookies.remove('accessToken');
    Cookies.remove('sessionId');
    Cookies.remove('pinStatus');
    dispatch(ActionCreator.resetStore());
    setStep(1);
  };

  const getControl = (item) => {
    switch (item.type) {
      case 'text':
        return <button className={styles['button-text']} onClick={onForgotButtonClick}>{item.value}</button>;

      case 'image':
        return (
          <button className={styles['button-text']} onClick={onDeleteButtonClick}>
            <img src={item.value} alt="delete button" />
          </button>
        );

      default:
        return (
          <button className={styles['button-symbol']} onClick={onSymbolButtonClick}>{item.value}</button>
        );
    }
  };

  return (
    <>
      <div className={styles.top}>
        <p className={styles.title}>Введите код доступа</p>
        <div className={styles['indicators-wrapper']}>
          {indicators.map((item) => (
            <div className={getIndicatorClassName(item)} key={item}></div>
          ))}
        </div>
      </div>
      <div className={styles.bottom}>
        {controls.map((item) => (
          <div className={styles['button-wrapper']} key={item.value}>{getControl(item)}</div>
        ))}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <FormInput
          formik={formik}
          name="code"
          type="hidden"
          fieldType="masked-input"
          mask="9999"
          maskChar="X"
        />
      </form>
      <button className={styles['button-exit']} onClick={onExitButtonClick}>
        Выйти
      </button>
    </>
  );
};

export default UserPinCheckForm;
