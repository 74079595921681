import humps from 'humps';
import Cookies from 'js-cookie';

import { ActionCreator } from '../store/actions/user';

import { adaptRequestBodyPhoneField } from '../utils/helpers';

const login = (body) => (dispatch, _getState, api) => api
  .post('/user/login', humps.decamelizeKeys(adaptRequestBodyPhoneField(body)))
  .then(({ data }) => {
    Cookies.set('sessionId', data.session_id);
  });

const sendCode = (body) => (dispatch, _getState, api) => api
  .post('/user/code', humps.decamelizeKeys(body))
  .then(({ data }) => {
    Cookies.set('accessToken', data.access_token);
  });

const checkPin = (body) => (dispatch, _getState, api) => api
  .post('/user/pin', humps.decamelizeKeys(body))
  .then(({ data }) => {
    Cookies.set('accessToken', data.access_token);
  });

const setPin = (body) => (dispatch, _getState, api) => api.post('/user/set_pin', humps.decamelizeKeys(body));

const uploadDocument = (binary) => (dispatch, _getState, api) => api.post('/user/passport', binary, {
  headers: {
    'Content-Type': 'multipart/form-data',
  }
});

const sendLegalData = (body) => (dispatch, _getState, api) => api.post('/user/legal', humps.decamelizeKeys(body));

const getUserStatus = () => (dispatch, _getState, api) => api.get('/user/status');

const getUserInfo = () => (dispatch, _getState, api) => api
  .get('/user/info')
  .then(({ data }) => {
    dispatch(ActionCreator.setUserInfo(humps.camelizeKeys(data)));
  });

export {
  login,
  sendCode,
  checkPin,
  setPin,
  uploadDocument,
  sendLegalData,
  getUserStatus,
  getUserInfo
};
