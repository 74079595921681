import humps from 'humps';

import { ActionCreator } from '../store/actions/history';

const getHistory = (params, oldData) => (dispatch, _getState, api) => api
  .get('/history', { params: humps.decamelizeKeys(params) })
  .then(({ data }) => {
    dispatch(ActionCreator.setHistory(humps.camelizeKeys([...oldData, ...data])));
  });

const getCurrenciesList = () => (dispatch, _getState, api) => api
  .get('/currencies_list')
  .then(({ data }) => {
    dispatch(ActionCreator.setCurrenciesList(humps.camelizeKeys(data)));
  });


export { getHistory, getCurrenciesList };
