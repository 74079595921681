import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reduxReset from 'redux-reset';

import rootReducer from './reducers/root-reducer';
import api from '../services/api';

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument(api)),
    reduxReset(),
  ),
);
