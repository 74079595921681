import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import InfiniteScroll from 'react-infinite-scroll-component';
import HistoryListItem from './HistoryListItem';
import Loading from '../UI/Loading';

import { getHistory } from '../../services/history';

import styles from './HistoryList.module.scss';

const HistoryList = ({ params, setParams }) => {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const history = useSelector((state) => state.history.history);

  const getData = (oldData) => {
    dispatch(getHistory(params, oldData)).catch(() => {
      toast.error('Не удалось загрузить историю операций');
    });
  };

  const next = () => {
    const newOffset = params.offset + 10;

    if (history.length !== newOffset) {
      setHasMore(false);
    } else {
      setParams({
        ...params,
        offset: newOffset,
      });
    }
  };

  useEffect(() => {
    if (loaded) {
      const currentHistory = params.offset === 0 ? [] : history;
      getData(currentHistory);
    }
  }, [params]);

  useEffect(() => {
    getData([]);
    setLoaded(true);
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  if (history.length === 0) {
    return null;
  }

  console.log(history);

  return (
    <div className={styles.listContainer}>
      <InfiniteScroll
        dataLength={history.length}
        next={next}
        hasMore={hasMore}
        loader={<Loading />}
      >
        {history.map((item) => (
          <HistoryListItem operation={item} key={item.id} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default HistoryList;
