import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import { ActionCreator } from '../../store/actions/user';

import styles from './UserAuthStatus.module.scss';

import AuthAwaitImage from '../../assets/images/auth-await.svg';
import AuthErrorImage from '../../assets/images/auth-error.svg';
import AuthSuccessImage from '../../assets/images/auth-success.svg';

const UserAuthStatus = ({ userInfo, setStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onContinueButtonClick = () => {
    navigate('/main');
  };

  const onRepeatButtonClick = () => {
    setStep(1);
  };

  const onExitButtonClick = () => {
    Cookies.remove('accessToken');
    Cookies.remove('sessionId');
    Cookies.remove('pinStatus');
    dispatch(ActionCreator.resetStore());
    setStep(1);
  };

  return (
    <>
      {userInfo.status === 1 && (
        <>
          <img
            src={AuthAwaitImage}
            className={styles.image}
            alt="login image"
          />
          <fieldset className={styles.fieldset}>
            <legend>В процессе проверки документов</legend>
          </fieldset>
        </>
      )}
      {userInfo.status === 2 && (
        <>
          <img
            src={AuthSuccessImage}
            className={styles.image}
            alt="login image"
          />
          <fieldset className={styles.fieldset}>
            <legend>Идентификация прошла успешно</legend>
          </fieldset>
          <button className={styles.button} onClick={onContinueButtonClick}>
            Продолжить
          </button>
        </>
      )}
      {userInfo.status === 4 && (
        <>
          <img
            src={AuthErrorImage}
            className={styles.image}
            alt="login image"
          />
          <fieldset className={styles.fieldset}>
            <legend>Идентификация не прошла</legend>
          </fieldset>
          <button className={styles.button} onClick={onRepeatButtonClick}>
            Повторить
          </button>
        </>
      )}
      <button className={styles['button-exit']} onClick={onExitButtonClick}>
        Выйти
      </button>
    </>
  );
};

export default UserAuthStatus;
