import { ActionType } from '../actions/conversion';

const initialState = {
  currencies: [],
};

const setCurrencies = (state, data) => ({
  ...state,
  currencies: data,
});

const conversion = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_CURRENCIES:
      return setCurrencies(state, action.payload);

    default:
      return state;
  }
};

export default conversion;
