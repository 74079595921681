import styles from './SubmitButton.module.scss';

const SubmitButton = ({ title, value, currency }) => (
  <button type="submit" className={styles.button}>
    <span>{title}</span>
    <span>{value ? value : '0'}</span>
    <span>{currency}</span>
  </button>
);

export default SubmitButton;
