import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

import FormInput from '../UI/FormInput';

import { login } from '../../services/user';

import { phoneNumberFormatter } from '../../utils/formatters';

import styles from './UserLoginForm.module.scss';

// import LogoImage from '../../assets/images/login.svg';

const UserLoginForm = ({ setStep, userPhone, setUserPhone }) => {
  const [isRulesCheckbox, setIsRulesCheckbox] = useState(false);
  const [isPolicyCheckbox, setIsPolicyCheckbox] = useState(false);

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      phone: userPhone || '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    phone: Yup.string()
      .length(10, 'Введите корректный номер телефона')
      .required('Введите номер телефона'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      dispatch(
        login({
          ...values,
          deviceId: 'sampleText',
        }),
      )
        .then(() => {
          setStep(2);
        })
        .catch(() => {
          toast.error('Произошла ошибка при отправке данных');
        }),
  });

  useEffect(() => {
    setUserPhone(formik.values.phone);
  }, [formik.values.phone]);

  const handleRulesCheckboxChange = () => {
    setIsRulesCheckbox(!isRulesCheckbox);
  };

  const handlePolicyCheckboxChange = () => {
    setIsPolicyCheckbox(!isPolicyCheckbox);
  };

  return (
    <>
      {/* <img src={LogoImage} className={styles.image} alt="login image" /> */}
      <h1 className={styles.title}>
        Войти или создать
        <br />
        личный кабинет
      </h1>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <FormInput
          formik={formik}
          label="Номер телефона"
          name="phone"
          fieldType="masked-input"
          mask="+7\-(999)-999-99-99"
          maskChar="X"
          onChange={(evt) =>
            formik.setFieldValue(
              'phone',
              phoneNumberFormatter(evt.target.value),
            )
          }
        />
        <div className={styles.confirm}>
          <p className={styles.confirm__title}>
            Входя в аккаунт или создавая новый, вы соглашаетесь с
          </p>
          <div className={styles.confirm__block}>
            <input
              type="checkbox"
              id="rules"
              name="rules"
              className={styles.confirm__field}
              onChange={handleRulesCheckboxChange}
            />
            <label htmlFor="rules" className={styles.confirm__label}></label>
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.confirm__link}
            >
              Правилами и условиями
            </a>
          </div>
          <div className={styles.confirm__block}>
            <input
              type="checkbox"
              id="policy"
              name="policy"
              className={styles.confirm__field}
              onChange={handlePolicyCheckboxChange}
            />
            <label htmlFor="policy" className={styles.confirm__label}></label>
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.confirm__link}
            >
              Положением о конфиденциальности
            </a>
          </div>
        </div>
        <button
          className={styles.button}
          type="submit"
          disabled={!isRulesCheckbox || !isPolicyCheckbox}
        >
          Получить код
        </button>
      </form>
    </>
  );
};

export default UserLoginForm;
