import imgUSDT from "../../assets/images/imgUSDT.svg";
import imgXBT from "../../assets/images/imgXBT.svg";
import imgETH from "../../assets/images/imgETH.svg";

const swiperLogos = {
  usdt: imgUSDT,
  btc: imgXBT,
  eth: imgETH,
};

export { swiperLogos };
