import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

import FormInput from '../UI/FormInput';

import { sendLegalData } from '../../services/user';

import styles from './UserDocumentForm.module.scss';
import { codeNumberFormatter, phoneNumberFormatter } from '../../utils/formatters';

const UserDocumentForm = ({ setStep }) => {
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      detail: '',
      phone: '',
      email: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    detail: Yup.string()
      .length(10, 'Введите корректный ИНН или ОГРН')
      .required('Введите ИНН или ОГРН'),
    phone: Yup.string()
      .length(10, 'Введите корректный номер телефона')
      .required('Введите номер телефона'),
    email: Yup.string()
      .email('Введите корректный E-mail')
      .required('Введите E-mail'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      dispatch(sendLegalData(values))
        .then(() => {
          setStep(5);
        })
        .catch(() => {
          toast.error('Произошла ошибка при отправке данных');
        }),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormInput
        formik={formik}
        label='ИНН или ОГРН'
        name="detail"
        fieldType="masked-input"
        mask="9999999999"
        maskChar="X"
        onChange={(evt) => formik.setFieldValue('detail', codeNumberFormatter(evt.target.value))}
      />
      <FormInput
        formik={formik}
        label="Номер телефона"
        name="phone"
        fieldType="masked-input"
        mask="+7\-(999)-999-99-99"
        maskChar="X"
        onChange={(evt) => formik.setFieldValue('phone', phoneNumberFormatter(evt.target.value))}
      />
      <FormInput
        formik={formik}
        label='E-mail'
        name="email"
      />
      <button className={styles.button} type="submit">
        Отправить
      </button>
    </form>
  );
};

export default UserDocumentForm;
