import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import SwiperCards from '../SwiperCards';

import { ActionCreator } from '../../store/actions/user';

import { parsePhone } from '../../utils/helpers';

import styles from './UserBlock.module.scss';

import testAva from '../../assets/images/testAva.png';
import exitButton from '../../assets/images/exit.svg';

const UserBlock = ({ setActiveCard }) => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const dispatch = useDispatch();

  const onExitButtonClick = () => {
    Cookies.remove('accessToken');
    Cookies.remove('sessionId');
    Cookies.remove('pinStatus');
    dispatch(ActionCreator.resetStore());
  };

  return (
    <div className={styles.container}>
      <header className={styles.profileContainer}>
        <img src={testAva} alt="profile-avatar" />
        <div className={styles.profileInfo}>
          <div className={styles.name}>{userInfo.name}</div>
          <div className={styles.phone}>{parsePhone(userInfo.phone)}</div>
        </div>
        <button onClick={onExitButtonClick} className={styles.button}>
          <img src={exitButton} alt="exit button" />
        </button>
      </header>
      <SwiperCards setActiveCard={setActiveCard} />
    </div>
  );
};

export default UserBlock;
