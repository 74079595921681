import CurrencyInput from 'react-currency-input-field';
import ReactInputMask from 'react-input-mask';

import styles from './FormInput.module.scss';

const FormInput = ({ formik, label, name, fieldType, ...otherProps }) => {
  const getElement = () => {
    switch (fieldType) {
      case 'select':
        return (
          <select
            name={name}
            className={styles.formInput}
            onChange={formik.handleChange}
            value={formik.values[name]}
            // {...formik.getFieldProps([name])}
            {...otherProps}
          >
            {otherProps.options.map((item) => (
              <option value={item.value} key={item.value}>
                {item.title}
              </option>
            ))}
          </select>
        );

      case 'masked-input':
        return (
          <ReactInputMask
            name={name}
            className={styles.formInput}
            value={formik.values[name]}
            {...otherProps}
          />
        );
  
      default:
        return (
          <input
            name={name}
            className={styles.formInput}
            onChange={formik.handleChange}
            value={formik.values[name]}
            autoComplete="off"
            {...otherProps}
          />
        );
    }
  };

  return (
    <div className={styles.group}>
      {label === 'Сумма' ? (
        <CurrencyInput
          name={name}
          className={styles.formInput}
          autoComplete="off"
          suffix={` ${otherProps.currency}`}
          decimalsLimit={10}
          decimalSeparator="."
          groupSeparator="'"
          allowNegativeValue={false}
          disableAbbreviations={true}
          value={formik.values[name]}
          {...otherProps}
        />
      ) : (
        getElement()
      )}

      <label
        className={`${formik.values[name]?.length ? styles.shrink : ''} ${styles.inputLabel
          }`}
      >
        {label}
      </label>

      {formik.errors[name] && formik.touched[name] ? (
        <div className={styles.error}>{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default FormInput;
