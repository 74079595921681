import React from 'react';
import { Link } from 'react-router-dom';

import { parseDateTimeFormat } from '../../utils/helpers';

import { OperationType, operaitonStatuses } from '../../utils/constants';

import styles from './HistoryListItem.module.scss';

const HistoryListItem = ({ operation }) => {
  const operationType = OperationType[operation.type];

  const getValue = () => {
    switch (operation.type) {
      case OperationType.deposit.type:
        return `+ ${operation.amount} ${operation.currency.toUpperCase()}`;

      case OperationType.conversion.type:
        return `- ${operation.fromAmount} ${operation.fromCurrency.toUpperCase()} / + ${operation.toAmount} ${operation.toCurrency.toUpperCase()}`;

      case OperationType.withdrawal.type:
        return `- ${operation.amount} ${operation.currency.toUpperCase()}`;

      default:
        return null;
    }
  };

  return (
    <Link to={`/history/${operation.id}`} className={styles.itemContainer}>
      <div className={styles.left}>
        <img src={operationType.image} alt="transaction-type-icon" />
        <div className={styles.leftInfo}>
          <div className={styles.transaction}>
            {operationType.title.toUpperCase()}
          </div>
          <div className={styles.bottomGray}>{parseDateTimeFormat(operation.createdAt)}</div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles[operationType.selector]}>{getValue()}</div>
        <div className={styles.bottomGray}>{operaitonStatuses[operation.status]}</div>
      </div>
    </Link>
  );
};

export default HistoryListItem;
