import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';

import { login } from '../../services/user';

import styles from './Timer.module.scss';

const Timer = ({ userPhone }) => {
  const [timerCount, setTimerCount] = useState(30);

  const dispatch = useDispatch();

  useEffect(() => {
    if (timerCount) {
      setTimeout(() => setTimerCount(timerCount - 1), 1000);
    }
  }, [timerCount]);

  const handleRepeatCodeSendButtonClick = () => {
    dispatch(
      login({
        phone: userPhone,
        deviceId: 'sampleText',
      }),
    )
      .then(() => {
        setTimerCount(30);
      })
      .catch(() => {
        toast.error('Произошла ошибка при отправке данных');
      })
  };

  return timerCount ? (
    <p className={styles.timer}>
      Запросить код через 00:{timerCount >= 10 ? timerCount : `0${timerCount}`}
    </p>
  ) : (
    <button
      className={styles.button}
      type="submit"
      onClick={handleRepeatCodeSendButtonClick}
      disabled={timerCount}
    >
      Запросить код повторно
    </button>
  );
};

export default Timer;
