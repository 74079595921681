import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

import { uploadDocument } from '../../services/user';

import styles from './UserPassportForm.module.scss';

import UploadImage from '../../assets/images/button.svg'

const UserPassportForm = ({ setStep }) => {
  const [fileName, setFileName] = useState('');

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      passport: null,
    }),
    [],
  );

  const validationSchema = Yup.object({
    passport: Yup.string().required('Добавьте фотографию'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => dispatch(uploadDocument(values.passport))
      .then(() => {
        setStep(5);
      })
      .catch(() => {
        toast.error('Произошла ошибка при отправке данных');
      })
  });

  const onPassportFieldChange = (evt) => {
    const file = evt.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      formik.setFieldValue('passport', e.target.result);
      setFileName(evt.target.files[0].name);
    };

    reader.readAsArrayBuffer(file);
  }

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <input
        id="passport"
        name="passport"
        type="file"
        required
        className={styles.input}
        accept=".jpg, .jpeg, .png"
        onChange={onPassportFieldChange}
      />
      <div className={styles.inputWrapper}>
        <label htmlFor="passport" className={styles.label}>
          <img src={UploadImage} alt="upload image" />
          <div className={styles.info}>
            {fileName}
          </div>
        </label>
      </div>
      <button className={styles.button} type="submit">
        Отправить
      </button>
    </form >
  );
};

export default UserPassportForm;
