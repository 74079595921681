import BtnConversionIcon from '../../assets/images/ServicesIcons/Conversion-icon-active-Color.svg';
import BtnSendIcon from '../../assets/images/ServicesIcons/Send-icon-active-Color.svg';

const mainButtons = [
  {
    title: 'Конвертировать',
    type: 'conversion',
    image: BtnConversionIcon,
    selector: 'titleBuy',
  },
  {
    title: 'Отправить',
    type: 'withdrawal',
    image: BtnSendIcon,
    selector: 'titleSend',
  },
];

export { mainButtons };
