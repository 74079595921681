import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HistoryHeadNav from '../../components/UI/HistoryHeadNav';
import TransactionMainInfo from '../../components/UI/TransactionMainInfo';
import DetailsList from '../../components/DetailsList/indes';

import { OperationType } from '../../utils/constants';

const TransactionDetails = () => {
  const { id } = useParams();

  const history = useSelector((state) => state.history.history);

  const transaction = history.filter((item) => item.id === id)[0];

  const transactionInfo = OperationType[transaction.type];

  return (
    <>
      <HistoryHeadNav title={transactionInfo.title} />
      <TransactionMainInfo
        transactionInfo={transactionInfo}
        result={transaction}
        // rate={rate}
      />
      <DetailsList transaction={transaction} />
    </>
  );
};

export default TransactionDetails;
