import Cookies from 'js-cookie';

import UserPinCheckForm from '../UserPinCheckForm/inden';
import UserPinSetForm from '../UserPinSetForm/inden';

const UserPinForm = ({ setStep }) => {
  const pinStatus = Cookies.get('pinStatus');

  return (
    <>
      {pinStatus ? (
        <UserPinCheckForm setStep={setStep} />
      ) : (
        <UserPinSetForm setStep={setStep} />
      )}
    </>
  );
};

export default UserPinForm;
