import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from 'react-hot-toast';

import BalanceCard from "../BalanceCard";

import { getWallets } from '../../services/wallet';

import "swiper/scss";
import styles from "./SwiperCards.module.scss";

const SwiperCards = ({ setActiveCard }) => {
  const wallets = useSelector((state) => state.wallets.wallets);

  const dispatch = useDispatch();

  const windowSize = useRef(window.innerWidth);
  let slideCount = 1.2;

  useEffect(() => {
    dispatch(getWallets())
      .catch((err) => {
        toast.error(
          'Не удалось загрузить информацию о кошельках пользователя и их балансах',
        );
      });
  }, []);

  const onChangeActiveCard = (type) => {
    setActiveCard(type);
  };

  if (windowSize.current > 470) {
    slideCount = 1.45;
  }

  return (
    <Swiper
      centeredSlides={false}
      spaceBetween={16}
      slidesPerView={slideCount}
      className={styles.swiperCards}
    >
      {wallets.map((card) => (
        <SwiperSlide key={card.id}>
          <BalanceCard
            key={card.id}
            card={card}
            onChangeActiveCard={onChangeActiveCard}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperCards;
