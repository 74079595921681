import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import Cookies from 'js-cookie';

import Login from './pages/Login/Login';
import MainPage from './pages/Main/MainPage';
import TransactionDetails from './pages/TransactionDetails/TransactionDetails';
import NotFoundPage from './pages/NotFound/NotFoundPage';
import Loading from './components/UI/Loading';

import { getUserInfo } from './services/user';
// import PrivateRoute from './components/PrivateRoute';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      dispatch(getUserInfo()).catch(() => {
        // toast.error('Не удалось загрузить информацию о пользователе');
      });
    }
  }, []);

  return (
    <div className="app-container">
      <Suspense fallback={<Loading />}>
        <Router>
          <Routes>
            {/* <PrivateRoute path="/" component={MainPage} />
            <PrivateRoute
              path="/history/:testId"
              component={TransactionDetails}
            />
            <PrivateRoute path="*" component={NotFoundPage} /> */}
            <Route path="/" element={<Login />} />
            <Route path="/main" element={<MainPage />} />
            <Route path="/history/:id" element={<TransactionDetails />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </Suspense>
      <Toaster />
    </div>
  );
};

export default App;
