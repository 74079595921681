export const ActionType = {
  SET_HISTORY: 'SET_HISTORY',
  SET_CURRENCIES_LIST: 'SET_CURRENCIES_LIST',
};

export const ActionCreator = {
  setHistory: (data) => ({
    type: ActionType.SET_HISTORY,
    payload: data,
  }),
  setCurrenciesList: (data) => ({
    type: ActionType.SET_CURRENCIES_LIST,
    payload: data,
  }),
};
