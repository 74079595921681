import React from 'react';
import ReactDOM from 'react-dom';

import navCloseIcon from '../../../assets/images/navCloseIcon.svg';

import styles from './Modal.module.scss';

const Modal = ({ children, setTransactionInfo }) => {
  const closeModal = () => {
    setTransactionInfo(null);
  };

  return ReactDOM.createPortal(
    <>
      <div className={styles.backdrop} onClick={closeModal}></div>
      <div className={styles.modal}>
        <div className={styles.modalHead}>
          <button onClick={closeModal}>
            <img src={navCloseIcon} alt={'navCloseIcon'} />
          </button>
        </div>
        {children}
      </div>
    </>,
    document.getElementById('modal'),
  );
};

export default Modal;
