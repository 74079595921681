import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

import Timer from '../Timer';
import FormInput from '../UI/FormInput';

import { sendCode } from '../../services/user';

import { codeNumberFormatter } from '../../utils/formatters';
import { parsePhone } from '../../utils/helpers';

import styles from './UserCodeForm.module.scss';

import backButtonImage from '../../assets/images/back.svg';

const UserCodeForm = ({ userPhone, setStep }) => {
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      code: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    code: Yup.string()
      .length(4, 'Введите корректный код')
      .required('Введите код'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      dispatch(sendCode(values))
        .then(() => {
          setStep(3);
        })
        .catch((err) => {
          if (err.status === 400) {
            formik.setFieldError('code', 'Неверный код, попробуйте еще раз');
          } else {
            toast.error('Произошла ошибка при отправке данных');
          }
        }),
  });

  useEffect(() => {
    if (formik.values.code.length === 4) {
      formik.submitForm();
    }
  }, [formik.values.code]);

  const handleBackButtonClick= () => {
    setStep(1);
  };

  return (
    <>
      <button
        className={styles.button}
        onClick={handleBackButtonClick}
      >
        <img src={backButtonImage} alt="Back" />
      </button>
      <h1 className={styles.title}>Введите код из СМС</h1>
      <p className={styles.note}>
        Отправили на&nbsp;
        {parsePhone(`+7${userPhone}`)}
      </p>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <FormInput
          formik={formik}
          label="Четырехзначный код"
          name="code"
          fieldType="masked-input"
          mask="9999"
          maskChar="X"
          onChange={(evt) =>
            formik.setFieldValue('code', codeNumberFormatter(evt.target.value))
          }
        />
      </form>
      <Timer
        userPhone={userPhone}
      />
    </>
  );
};

export default UserCodeForm;
