import React, { useState } from 'react';

import Modal from '../UI/Modal';
import FormConversion from '../FormConversion';
import FormSend from '../FormSend';

import { mainButtons, transactionTypes } from '../../utils/constants';

import styles from './MainButtons.module.scss';

const MainButtons = ({ activeCard, setParams }) => {
  const [transactionInfo, setTransactionInfo] = useState(null);

  const openModal = (info) => {
    setTransactionInfo(info);
  };

  return (
    <>
      <div className={styles.container}>
        {mainButtons.map((item) => (
          <button
            className={styles.buttonContainer}
            onClick={() => openModal(item)}
            key={item.title}
          >
            <img src={item.image} alt="button-icon" />
            <div>{item.title}</div>
          </button>
        ))}
      </div>
      {transactionInfo && (
        <Modal setTransactionInfo={setTransactionInfo}>
          {transactionInfo.type === transactionTypes.CONVERSION && (
            <FormConversion
              transactionInfo={transactionInfo}
              setTransactionInfo={setTransactionInfo}
              activeCard={activeCard}
              setParams={setParams}
            />
          )}
          {transactionInfo.type === transactionTypes.SEND && (
            <FormSend
              transactionInfo={transactionInfo}
              setTransactionInfo={setTransactionInfo}
              activeCard={activeCard}
              setParams={setParams}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default MainButtons;
