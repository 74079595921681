export const ActionType = {
  SET_USER_INFO: 'SET_USER_INFO',
  RESET: 'RESET',
};

export const ActionCreator = {
  setUserInfo: (data) => ({
    type: ActionType.SET_USER_INFO,
    payload: data,
  }),
  resetStore: () => ({ type: ActionType.RESET }),
};
