import moment from "moment";

import BuyIcon from "../../assets/images/ServicesIcons/Buy-icon-active-Grey.svg";
import SendIcon from "../../assets/images/ServicesIcons/Send-icon-active-Grey.svg";
import ConversionIcon from "../../assets/images/ServicesIcons/Conversion-icon-active-Grey.svg";

const OperationType = {
  deposit: {
    type: 'deposit',
    title: 'Покупка',
    image: BuyIcon,
    selector: 'valueGreen',
  },
  withdrawal: {
    type: 'withdrawal',
    title: 'Отправка',
    image: SendIcon,
    selector: 'valueBlack',
  },
  conversion: {
    type: 'conversion',
    title: 'Конвертация',
    image: ConversionIcon,
    selector: 'valueGreen',
  },
};

const operaitonStatuses = {
  1: 'Создано',
  2: 'Успешно',
  3: 'Не успешно',
};

const initialParams = {
  startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
  endDate: moment().add('days', 1).format('YYYY-MM-DD'),
  offset: 0,
  limit: 10,
  currency: '',
};

export { OperationType, operaitonStatuses, initialParams };
