import React from 'react';

import { parseDateTimeFormat } from '../../utils/helpers';

import { operaitonStatuses } from '../../utils/constants';

import styles from './DetailsList.module.scss';

const DetailsList = ({ transaction }) => (
  <div className={styles.detailsList}>
    <div className={styles.listItem}>
      <div className={styles.name}>ID транзакции</div>
      <div className={styles.value}>{transaction.id}</div>
    </div>
    <div className={styles.listItem}>
      <div className={styles.name}>Дата</div>
      <div className={styles.value}>
        {parseDateTimeFormat(transaction.createdAt)}
      </div>
    </div>
    <div className={styles.listItem}>
      <div className={styles.name}>Статус транзакции</div>
      <div className={styles.value}>{operaitonStatuses[transaction.status]}</div>
    </div>
  </div>
);

export default DetailsList;
