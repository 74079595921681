import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

import FormWrapper from '../UI/FormWrapper';
import FormInput from '../UI/FormInput';
import TransactionMainInfo from '../UI/TransactionMainInfo';

import { createWalletPayout, getWallets } from '../../services/wallet';

import { initialParams } from '../../utils/constants';

import styles from './FormSend.module.scss';

const FormSend = ({
  transactionInfo,
  setTransactionInfo,
  activeCard,
  setParams,
}) => {
  const [result, setResult] = useState(null);

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      currency: activeCard.type || '',
      account: '',
      amount: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    currency: Yup.string().required('Выберите валюту списания'),
    account: Yup.string().required('Введите целевой аккаунт'),
    amount: Yup.string().required('Введите сумму'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ currency, ...params }) =>
      dispatch(createWalletPayout(currency, params))
        .then(({ data }) => {
          setResult(data);
          dispatch(getWallets()).catch(() => {
            toast.error(
              'Не удалось загрузить информацию о кошельках пользователя и их балансах',
            );
          });
          setParams({ ...initialParams });;
        })
        .catch((err) => {
          let errorText = '';

          if (err.data.detail) {
            errorText = errorText + err.data.detail;
          }
          if (err.data.non_field_errors) {
            errorText = errorText + err.data.non_field_errors.join('. ');
          }
          
          toast.error(`Произошла ошибка при создании операции. ${errorText}`);
        }),
  });

  const onAmountFieldChange = (value) => {
    formik.setFieldValue('amount', value);
  };

  const onCloseButtonClick = () => setTransactionInfo(null);

  return (
    <>
      {result ? (
        <div className={styles.mainInfoWrapper}>
          <TransactionMainInfo
            transactionInfo={transactionInfo}
            setTransactionInfo={setTransactionInfo}
            result={result}
          />
          <button onClick={onCloseButtonClick} className={styles.button}>
            Закрыть
          </button>
        </div>
      ) : (
        <FormWrapper
          transactionInfo={transactionInfo}
          currency={activeCard.name}
          description="Криптовалюта будет зачислена на кошелек сразу после отправления"
          value={formik.values.amount}
          onSubmit={formik.handleSubmit}
        >
          <FormInput
            formik={formik}
            label="Введите адрес кошелька"
            name="account"
          />

          <FormInput
            formik={formik}
            label="Сумма"
            name="amount"
            currency={activeCard.altname}
            onValueChange={onAmountFieldChange}
          />
        </FormWrapper>
      )}
    </>
  );
};

export default FormSend;
